import { createAction, props } from '@ngrx/store';
import { UserKeys } from '@prc-lib-core';
import { UserState } from '../reducers/user.reducer';

export enum UserActionTypes {
  Init = '[User] Init',
  Update = '[User] Update',
  UpdateInfo = '[User] Update Info',
  SetData = '[User] Set Data',
  SubscribeNewsletter = '[User] Subscribe newsletter',
}

export interface UserSubscribeNewsletterPayload {
  email: string;
}

export const UserInitAction = createAction(UserActionTypes.Init);

export const UserUpdateAction = createAction(UserActionTypes.Update, props<{ payload: UserState }>());

export const UserUpdateInfoAction = createAction(UserActionTypes.UpdateInfo, props<{ payload: { [key in keyof UserState]?: unknown } }>());

export const UserSetData = createAction(UserActionTypes.SetData, props<{ payload: { key: UserKeys; value: unknown } }>());

export const UserSubscribeNewsletterAction = createAction(UserActionTypes.SubscribeNewsletter, props<UserSubscribeNewsletterPayload>());
